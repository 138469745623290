<template>
  <v-container>
    <v-row class="mt-8">
      <v-col align="center">
        <p class="text-h3">
          Gallery
        </p>
        <p class="text-h5">
          Click on a photo to open the gallery
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" lg="4" xl="3" v-for="(image, index) in images" :key="index" align="center">
        <v-img :src="image.src" width="330" height="300" @click="openGallery(index)" style="cursor: pointer"></v-img>
      </v-col>
    </v-row>
    <LightBox ref="lightbox" :media="images" :showLightBox="false"></LightBox>
  </v-container>
</template>

<script>
import LightBox from 'vue-it-bigger'
import axios from 'axios'

import('vue-it-bigger/dist/vue-it-bigger.min.css')

export default {
  components: {LightBox},
  name: "GalleryView",
  metaInfo: {
    title: 'Gallery',
  },
  data() {
    return {
      images: []
    }
  },
  created() {
    this.getImages()
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    },
    getImages() {
      axios.get('/api/gallery')
          .then((result) => this.images = result.data.map(img => {
            return {
              thumb: this.getAssetImg(img),
              src: this.getAssetImg(img)}
          }))
    }
  }
};
</script>

<style scoped>
</style>
